import React from 'react';

export const FlexibleLayout = (props: { layoutName: String }) => {
  const { layoutName } = props;

  // We 'as any' these but we could grab the types from the generated files;

  switch (layoutName) {
    case 'TwoColumn':
      //return <TwoColumn {...(props as any)} />;
      return null;
    default:
      console.log(`Flexible layout mapping not found: ${layoutName}`);
      return null;
  }
};
